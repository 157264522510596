import { Fragment } from 'react'
import Link from 'next/link'
import Image from 'next/image'
import { Popover, Transition } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'

const navigation = [
  { name: 'Changelog', href: '#' },
  { name: 'About', href: '#' },
  { name: 'Partners', href: '#' },
  { name: 'News', href: '#' },
]

const Navbar = () => (
  <Popover as="header" className="relative">
    <div>
      <nav
        className="relative mx-auto flex items-center justify-between px-6 pt-6 xl:px-8"
        aria-label="Global"
      >
        <div className="flex flex-1 items-center">
          <div className="flex w-full items-center justify-between lg:w-auto">
            <Link href="/">
              <a>
                <span className="sr-only">Technavix</span>
                <Image
                  src="/images/technavix-color.png"
                  alt="Technavix Logo"
                  width={156}
                  height={41}
                />
              </a>
            </Link>
            <div className="-mr-2 flex items-center lg:hidden">
              <Popover.Button className="focus-ring-inset inline-flex items-center justify-center rounded-md bg-slate-50 p-2 text-slate-400 hover:bg-slate-100 focus:outline-none focus:ring-2 focus:ring-primary-500">
                <span className="sr-only">Open main menu</span>
                <MenuIcon className="h-6 w-6" aria-hidden="true" />
              </Popover.Button>
            </div>
          </div>
          <div className="hidden space-x-10 lg:ml-10 lg:flex">
            {navigation.map((item) => (
              <Link key={item.name} href={item.href}>
                <a className="text-base font-medium text-slate-500 hover:text-slate-900">
                  {item.name}
                </a>
              </Link>
            ))}
          </div>
        </div>
        <div className="hidden lg:flex lg:items-center lg:space-x-6">
          <Link href="/contact">
            <a className="rounded-md border border-transparent bg-primary-500 py-2 px-6 text-base font-medium text-white hover:bg-primary-700">
              Let&apos;s Talk
            </a>
          </Link>
        </div>
      </nav>
    </div>

    <Transition
      as={Fragment}
      enter="duration-150 ease-out"
      enterFrom="opacity-0 scale-95"
      enterTo="opacity-100 scale-100"
      leave="duration-100 ease-in"
      leaveFrom="opacity-100 scale-100"
      leaveTo="opacity-0 scale-95"
    >
      <Popover.Panel
        focus
        className="absolute inset-x-0 top-0 z-30 origin-top transform p-2 transition lg:hidden"
      >
        <div className="overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
          <div className="flex items-center justify-between px-5 pt-4">
            <div>
              <Image
                src="/images/technavix-color.png"
                alt="Technavix Logo"
                width={156}
                height={41}
              />
            </div>
            <div className="-mr-2">
              <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-slate-400 hover:bg-slate-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500">
                <span className="sr-only">Close menu</span>
                <XIcon className="h-6 w-6" aria-hidden="true" />
              </Popover.Button>
            </div>
          </div>
          <div className="pt-5 pb-6">
            <div className="space-y-1 px-2">
              {navigation.map((item) => (
                <Link key={item.name} href={item.href}>
                  <a className="block rounded-md px-3 py-2 text-base font-medium text-slate-900 hover:bg-slate-50">
                    {item.name}
                  </a>
                </Link>
              ))}
            </div>
            <div className="mt-6 px-5">
              <Link href="/contact">
                <a className="block w-full rounded-md border border-transparent bg-primary-500 py-2 px-4 text-center font-medium text-white shadow hover:bg-primary-600">
                  Let&apos;s Talk
                </a>
              </Link>
            </div>
          </div>
        </div>
      </Popover.Panel>
    </Transition>
  </Popover>
)

export default Navbar
