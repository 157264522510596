import { Navbar, Footer } from '@components/common'

const Layout = ({ children }) => (
  <>
    <Navbar />
    <main className="overflow-hidden">{children}</main>
    <Footer />
  </>
)

export default Layout
